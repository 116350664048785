/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.7.1/dist/jquery.min.js
 * - /npm/jquery.mmenu@7.3.3/dist/jquery.mmenu.min.js
 * - /gh/heartyme/web.hearty.me@41214/js/alertify.custom.min.js
 * - /npm/firebase@8.10.1/firebase-app.js
 * - /npm/firebase@8.10.1/firebase-messaging.js
 * - /gh/chennien/dev.hearty.me@1d15c471cf4bb8d71a5c42977dcb5658ec7e6c3b/js/common.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
